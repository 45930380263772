import React from 'react';
import { Product } from '../../services/openapi'
import { ApiError } from '../../services/openapi';
import { Col, Row } from 'react-bootstrap';

type ProductProps = {
    product: Product;
    rowColor: string;
    className?: string;
};

const ProductItem = ({ product, rowColor, className }: ProductProps) => {
    return (
        <>
            <Row className={("qvend-table-font " + (className ?? ""))} style={{ background: rowColor, color: "black" }}>
                <Col md={4} xs={6} className="qvend-table table-column-first">{product.name}</Col>
                <Col md={4} xs={3} className="qvend-table">{product.chargeAmount}</Col>
                <Col md={4} xs={3} className="qvend-table">{product.note}</Col>
            </Row>
        </>
    );
};

export default ProductItem;
