
import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Alert, Form, FormCheck, Badge, CloseButton } from 'react-bootstrap';
import Card from '../../../components/Card';

import { AlertDefinitionItem } from '../../../features/alertDefinitions';
import { getAlertDefinitions, getAlertDefinitionCount } from '../../../services/api/alertDefinition';
import { ApiError, AlertDefinition } from '../../../services/openapi';

import { useKeycloak } from '@react-keycloak/web';

import SearchInput from '../../../components/SearchInput';
import ListPagination from '../../../components/ListPagination';
import RefreshPull from '../../../components/RefreshPull';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { Link } from 'react-router-dom';

const Locations = () => {
    const [alertDefinitions, setAlertDefinitions] = useState<AlertDefinition[]>([]);        
    const [alertDefinitionCount, setAlertDefinitionCount] = useState<string>("0");    
    const [pagingString, setPagingString] = useState<string>("0-25");    
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
    const [tableBodyHeight, setTableBodyHeight] = useState<number>(380);
    const [formData, setFormData] = useLocalStorage<string>("formData", "");
    const [searchData, setSearchData] = useLocalStorage<Record<string, string>>("searchData", {});
    const [filterData, setFilterData] = useLocalStorage<string>("filterData", "");
    const [currentPage, setCurrentPage] = useLocalStorage<number>("currentPage", 0);
    const [siteFilter, setSiteFilter] = useLocalStorage<number[]>("siteFilter", []);
    const [siteFilterQuery, setSiteFilterQuery] = useLocalStorage<string>("siteFilterQuery", "");
    const [errorMsg, setErrorMsg] = useState<string[]>([]);

    const { keycloak } = useKeycloak();
    const token = keycloak.token || '';

    const setData = (data: AlertDefinition) => {
        localStorage.setItem('AlertDefinition', JSON.stringify(data));
    }

    const handleFilter = (e: string): void => {
        setAlertDefinitions([]);
        setFilterData(e);
        setCurrentPage(0);
    }

    const handleSearch = (e: string): void => {
        setAlertDefinitions([]);
        searchData["search"] = e;
        setSearchData(searchData);
        formData === "" ? setFormData(" ") : setFormData("");       
        setCurrentPage(0);
    }

    const handleSearchColumn1 = (e: string): void => {
        setAlertDefinitions([]);
        searchData["alertTypeDescription"] = e;
        setSearchData(searchData);
        handleChange();
    }

    const handleSearchColumn2 = (e: string): void => {
        setAlertDefinitions([]);
        searchData["siteId"] = e;
        setSearchData(searchData);
        handleChange();
    }

    const handleSearchColumn3 = (e: string): void => {
        setAlertDefinitions([]);
        searchData["sendMail"] = e;
        setSearchData(searchData);
        handleChange();
    }    

    const handleSearchColumn4 = (e: string): void => {
        setAlertDefinitions([]);
        searchData["modificationDateTime"] = e;
        setSearchData(searchData);
        handleChange();
    }

    const handleRefreshButton = (): void => {
        setAlertDefinitions([]);
        formData === "" ? setFormData(" ") : setFormData("");
    }

    const delay = async (ms: number) => {
        await new Promise(resolve => setTimeout(resolve, ms));
    }

    let timer: NodeJS.Timeout | null = null;

    const submitSearch = () => {
        formData === "" ? setFormData(" ") : setFormData("");
    }

    const handleChange = () => {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(submitSearch, 2000);
        setCurrentPage(0);
    }

    const handlePaging = async (e: number) => {
        errorMsg.length = 0;

        do {
            await delay(1000);
        } while (isRefreshing);       

        setCurrentPage(e);

        const start = ((e + 1) * 25) - 25;
        const end = ((e + 1) * 25);
        const rangeString = `${start}-${end}`
        setPagingString(rangeString);

        document.querySelector('#refresh')?.classList.remove("d-none");
        setIsRefreshing(true);        

        getAlertDefinitions(keycloak.token || '', rangeString, { search: formData, Status: filterData, sites: siteFilter.join(), sitesQuery: siteFilterQuery }, searchData)
            .then((result) => {
                setAlertDefinitions(result.body);
                var totalCount = result.header.split('/')[1];
                setAlertDefinitionCount(totalCount);
                document.querySelector('#refresh')?.classList.add("d-none");
                setIsRefreshing(false);
            })
            .catch((error: ApiError) => {
                try {
                    document.querySelector('.errorMsg')?.classList.remove("d-none");
                    Object.entries<Record<string, any>>(JSON.parse(error.body)["errors"]).forEach((k) => { k[1].forEach((msg: string) => { errorMsg.push(msg); setErrorMsg(errorMsg); }) });                    
                } catch (ex) {
                    errorMsg.push("Error handling response");
                    setErrorMsg(errorMsg);                    
                }

                document.querySelector('#refresh')?.classList.add("d-none");
                setIsRefreshing(false);
            });
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>): boolean => {
        if (event.keyCode == 13) {
            event.preventDefault();
            return false;
        }
        return true;
    }

    const init = () => {
        document.querySelector('.errorMsg')?.classList.add("d-none");
        handlePaging(currentPage ?? 0);
        initSearchFields();
    };

    const initSearchFields = () => {
        Object.keys(searchData).map(x => {
            const search: HTMLInputElement | null = document.querySelector(`.${x}`) as HTMLInputElement;
            if (search) {
                search.value = searchData[x];
            }
        })
    }

    const onResize = () => {
        var e = document.querySelector(".vertical-scrollable") as HTMLElement;
        var h = window.innerHeight;
        e.style.height = (h - 284) + "px";
        e.style.maxHeight = (h - 280) + "px";
        setTableBodyHeight(h - 284);
    };

    useEffect(() => {
        window.addEventListener("resize", onResize);

        if (alertDefinitions?.length == 0) {
            init();
            onResize();
        }

        return () => {
            window.removeEventListener("resize", onResize);
        };

    }, [formData, filterData]);

    return (
        <>
            <Row className="">                  
                <Col className="">
                    <Card className="rounded">                        
                        <Card.Body className="">
                            <Row className="d-none d-md-block">
                                <Col>&nbsp;</Col>
                            </Row>
                            <Row className="d-none d-md-block">
                                <Col>&nbsp;</Col>
                            </Row>
                            <Row className="">
                                <Col sm="12" className="">    
                                    <h4 className="mb-2 qvend-title">Alarmen</h4>                                    
                                </Col>
                            </Row>                            
                            <Row style={{ fontSize: '11px' }}>
                                <Col md={3} xs={5} style={{ paddingRight: 0 }}>
                                    <div className="bd-example">
                                        <div>
                                            <SearchInput className="form-control search" type="search" placeholder="Search" onChange={handleSearch} />
                                        </div>
                                    </div>
                                </Col>
                                <Col md={3} xs={1}>
                                    <button type="button" className="btn btn-qvend" style={{ fontSize: '11px', padding: '0.5rem 0.3rem' }}>...</button>
                                </Col>                                    
                                <Col className="text-end">
                                    <button type="button" className="btn btn-qvend" style={{ fontSize: '11px' }}>Download</button>
                                </Col>
                            </Row>
                            <Row>
                            <Col md={12} xs={10} style={{ paddingRight: 0 }}>
                            </Col>
                            </Row>
                            <form className="" onKeyDown={handleKeyDown}>
                                <Row>
                                    <Col md={12} xs={10} style={{ paddingRight: 0 }}>
                                        <div>
                                            <fieldset className="form-control-sm" style={{ fontSize: '11px' }}>                                                
                                                {(siteFilter && siteFilter.length > 0 || siteFilterQuery && siteFilterQuery.length > 2) ? <span className="text-end">
                                                    <Badge pill bg="dark" className="p-1 " onClick={() => { setSiteFilter([]); setSiteFilterQuery(""); setAlertDefinitions([]); setCurrentPage(0); formData === "" ? setFormData(" ") : setFormData(""); window.localStorage.removeItem("searchDataSites") }}><CloseButton variant="white" /> Sites</Badge></span> : null}
                                            </fieldset>
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                            <Row>
                                <Col sm="12" xs="12" className="mt-2">
                                    <RefreshPull isRefreshing={isRefreshing} onSetPage={handlePaging} />
                                    <div className="table-responsive-lg" style={{ overflowX: 'hidden' }}>
                                        <div>
                                            <Row className="qvend-table-font" style={{ background: "#2b2e83", color: "#FFFFFF", overflowY: "scroll" }}>
                                                <Col xl={6} lg={6} md={6} sm={5} xs={5} className="qvend-table table-column-first">Type</Col>
                                                <Col xl={2} lg={2} md={2} sm={0} xs={0} className="qvend-table d-none d-md-block">Site</Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2} className="qvend-table">Mail</Col>
                                                <Col xl={2} lg={2} md={2} sm={5} xs={5} className="qvend-table">Gewijzigd</Col>
                                            </Row>                                            
                                        </div>
                                        <div className="vertical-scrollable" style={{ overscrollBehaviorY: 'contain' }}>
                                            <Row className={("qvend-table-font")} style={{ background: "#cfd5ea", color: "black" }}>
                                                <Col xl={6} lg={6} md={6} sm={5} xs={5} className="qvend-table search-in-row-first"><SearchInput className="form-control alertTypeDescription" type="search" onChange={handleSearchColumn1} /></Col>
                                                <Col xl={2} lg={2} md={2} sm={0} xs={0} className="qvend-table search-in-row-padding d-none d-md-block"><SearchInput className="form-control siteId" type="search" onChange={handleSearchColumn2} /></Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={2} className="qvend-table search-in-row-padding"><SearchInput className="form-control sendMail" type="search" onChange={handleSearchColumn3} /></Col>
                                                <Col xl={2} lg={2} md={2} sm={5} xs={5} className="qvend-table search-in-row-padding"><SearchInput className="form-control modificationDateTime" type="search" onChange={handleSearchColumn4} /></Col>
                                            </Row>
                                            <Row className="d-none errorMsg">
                                                <Col>
                                                    {
                                                        errorMsg.map((item, index) => {
                                                            return <Alert key={index} variant="warning" className="alert" role="alert">
                                                                <span>{item}</span>
                                                            </Alert>;
                                                        })
                                                    }
                                                </Col>
                                            </Row>
                                                {alertDefinitions.map((alertDefinition: AlertDefinition, index) => (
                                                    <Link to="/dashboard/details/alertDefinition" onClick={() => setData(alertDefinition)} key={alertDefinition.id}>
                                                        <AlertDefinitionItem
                                                            key={alertDefinition.id}
                                                            alertDefinition={alertDefinition}
                                                            rowColor={index % 2 ? "#cfd5ea" : "#e9ebf5"}
                                                        />
                                                    </Link>
                                                ))}                                            
                                        </div>                                        
                                    </div>
                                </Col>                              
                            </Row>
                            <ListPagination
                                articlesCount={alertDefinitionCount}
                                currentPage={currentPage}
                                onSetPage={handlePaging}
                                onRefresh={handleRefreshButton } />
                        </Card.Body>
                    </Card>
                </Col>                                    
            </Row>
        </>
    )
}

export default Locations
