import React from 'react'
import Index from '../views/dashboard/index'
import Settings from '../views/dashboard/menu/settings'

import { Routes, Route } from 'react-router-dom'

//TransitionGroup
import {TransitionGroup,CSSTransition} from "react-transition-group";

//Lists
import Transactions from '../views/dashboard/lists/transactions';
import Terminals from '../views/dashboard/lists/terminals';
import Vmcs from '../views/dashboard/lists/vmcs';
import Locations from '../views/dashboard/lists/locations';
import Products from '../views/dashboard/lists/products';
import Alerts from '../views/dashboard/lists/alerts';
import AlertDefinitions from '../views/dashboard/lists/alertDefinitions';
import Sites from '../views/dashboard/lists/sites';
import Profit from '../views/dashboard/lists/profit';
import Transaction from '../views/dashboard/details/transaction/transaction';
import Terminal from '../views/dashboard/details/terminal/terminal'
import Product from '../views/dashboard/details/product/product'
import Alert from '../views/dashboard/details/alert/alert'
import AlertDefinition from '../views/dashboard/details/alertDefinition/alertDefinition'
import Site from '../views/dashboard/details/site/site'
import TerminalMdbSetting from '../views/dashboard/details/terminal/terminalMdbSetting'
import Vmc from '../views/dashboard/details/vmc/vmc'
import VmcLinkedProducts from '../views/dashboard/details/vmc/vmc-linked-products'
import ProductVmcLink from '../views/dashboard/details/vmc/product-vmc-link'
import LinkLocationToTerminal from '../views/dashboard/items-linkers/location-to-terminal'
import LinkLocationToVmc from '../views/dashboard/items-linkers/location-to-vmc'
import LinkVmcToTerminal from '../views/dashboard/items-linkers/vmc-to-terminal'
import LinkProductToVmc from '../views/dashboard/items-linkers/product-to-vmc'
import LinkVmcTypeToVmc from '../views/dashboard/items-linkers/vmcType-to-vmc'


const DefaultRouter = () => {
    return (
        <TransitionGroup>
            <CSSTransition classNames="fadein" timeout={300}>
                <Routes>
                    <Route path="/" element={<Index />} exact />

                    <Route path="/menu/settings" element={<Settings /> } exact />

                    {/*lists */}
                    <Route path="/lists/transactions" element={<Transactions />} exact/>
                    <Route path="/lists/terminals" element={<Terminals />} exact/>
                    <Route path="/lists/vmcs" element={<Vmcs />} exact />
                    <Route path="/lists/locations" element={<Locations />} exact />
                    <Route path="/lists/products" element={<Products />} exact />
                    <Route path="/lists/alerts" element={<Alerts />} exact />
                    <Route path="/lists/alertDefinitions" element={<AlertDefinitions />} exact />
                    <Route path="/lists/sites" element={<Sites />} exact />
                    <Route path="/lists/profit" element={<Profit />} exact />


                    {/*Detail pages*/}
                    <Route path="/details/transaction" element={<Transaction />} exact />
                    <Route path="/details/terminal" element={<Terminal />} exact />
                    <Route path="/details/terminal-mdb-settings" element={<TerminalMdbSetting />} exact />
                    <Route path="/details/vmc" element={<Vmc />} exact />
                    <Route path="/details/vmc/vmc-linked-products" element={<VmcLinkedProducts />} exact />
                    <Route path="/details/vmc/product-vmc-link" element={<ProductVmcLink />} exact />
                    <Route path="/details/product" element={<Product />} exact />
                    <Route path="/details/alert" element={<Alert />} exact />
                    <Route path="/details/alertDefinition" element={<AlertDefinition />} exact />
                    <Route path="/details/site" element={<Site />} exact />

                    {/*Link pages*/}
                    <Route path="/items-linkers/location-to-terminal" element={<LinkLocationToTerminal />} exact />
                    <Route path="/items-linkers/location-to-vmc" element={<LinkLocationToVmc />} exact />
                    <Route path="/items-linkers/vmc-to-terminal" element={<LinkVmcToTerminal />} exact />
                    <Route path="/items-linkers/product-to-vmc" element={<LinkProductToVmc />} exact />
                    <Route path="/items-linkers/vmcType-to-vmc" element={<LinkVmcTypeToVmc />} exact />
                </Routes>
            </CSSTransition>
        </TransitionGroup>
    )
}

export default DefaultRouter
