import {
    EcrBasicSiteData,
    OpenAPI,
    EcrBasicSiteDataService
} from '../openapi';
import { ApiResult } from '../openapi/core/ApiResult';

const { get1 } = EcrBasicSiteDataService;

OpenAPI.BASE = process.env.REACT_APP_API_ENDPOINT as string;

export const getEcrBasicDataBySiteId = async (token: string, siteId: string): Promise<EcrBasicSiteData> => {
    return await get1(token, siteId);
};
