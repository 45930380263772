import React, { useState, useEffect, ChangeEvent, MouseEventHandler } from 'react';
import { Row, Col, Alert } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { DetailItem } from '../../../../features/details';
import { useKeycloak } from '@react-keycloak/web'
import { PropertyInfo } from '../../../../services/openapi/models/PropertyInfo';
import { Link, useNavigate } from "react-router-dom";
import { NotImplementedWarningModal } from '../../../../components/NotImplementedWarningModal';

import { ApiResult } from '../../../../services/openapi/core/ApiResult';
import { getProductVmcLinks } from '../../../../services/api/productVmcLink';
import { ProductVmcLink } from '../../../../services/openapi/models/ProductVmcLink';
import SearchInput from '../../../../components/SearchInput';
import ProductVmcLinkItem from '../../../../features/vmcs/ProductVmcLink';
import { useLocalStorage } from '../../../../hooks/useLocalStorage';
import { ApiError } from '../../../../services/openapi';
import ListPagination from '../../../../components/ListPagination';
import RefreshPull from '../../../../components/RefreshPull';

const VmcLinkedProductDetails = () => {
    const [vmc, setVmc] = useState<any>();
    const [productVmcLinks, setProductVmcLinks] = useState<ProductVmcLink[]>([]);
    const [productVmcLinksCount, setProductVmcLinksCount] = useState<string>("0");  
    const [showInputEle, setShowInputEle] = useState<boolean>(false);
    const [showModal, setShowModal] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
    const [formData, setFormData] = useLocalStorage<string>("formData", "");
    const [searchData, setSearchData] = useLocalStorage<Record<string, string>>("searchData", {});
    const [filterData, setFilterData] = useLocalStorage<string>("filterData", "");
    const [currentPage, setCurrentPage] = useLocalStorage<number>("currentPageProductVmcLink", 0);
    const [linkedProductIds, setLinkedProductIds] = useLocalStorage<number[]>("linkedProductIds", []);
    const [pagingString, setPagingString] = useState<string>("0-25");
    const [errorMsg, setErrorMsg] = useState<string[]>([]);

    const { keycloak } = useKeycloak();
    const token = keycloak.token || '';

    const navigate = useNavigate();

    const setProductVmcLinkData = (data: ProductVmcLink) => {
        localStorage.setItem('ProductVmcLink', JSON.stringify(data));
    }

    const delay = async (ms: number) => {
        await new Promise(resolve => setTimeout(resolve, ms));
    }

    const navigateBack = () => {
        navigate('/dashboard/details/vmc');
    };

    const initSearchFields = () => {
        Object.keys(searchData).map(x => {
            const search: HTMLInputElement | null = document.querySelector(`.${x}`) as HTMLInputElement;
            if (search) {
                search.value = searchData[x];
            }
        })
    }

    const handlePaging = async (e: number) => {
        do {
            await delay(1000);
        } while (isRefreshing);

        setCurrentPage(e);

        const start = ((e + 1) * 25) - 25;
        const end = ((e + 1) * 25);
        const rangeString = `${start}-${end}`
        setPagingString(rangeString);

        document.querySelector('#refresh')?.classList.remove("d-none");
        setIsRefreshing(true);

        getProductVmcLinks(keycloak.token || '', rangeString, { search: formData, linkTo: "Vmc", parentId: vmc!.id }, searchData)
            .then((result) => {
                setProductVmcLinks(result.body);
                var totalCount = result.header.split('/')[1];
                setProductVmcLinksCount(totalCount);
                setLinkedProductIds(productVmcLinks.map(x => x.productId));
                document.querySelector('#refresh')?.classList.add("d-none");
                setIsRefreshing(false);
            })
            .catch((error: ApiError) => {
                try {
                    document.querySelector('.errorMsg')?.classList.remove("d-none");
                    Object.entries<Record<string, any>>(JSON.parse(error.body)["errors"]).forEach((k) => { k[1].forEach((msg: string) => { errorMsg.push(msg); setErrorMsg(errorMsg); }) });
                } catch (ex) {
                    errorMsg.push("Error handling response");
                    setErrorMsg(errorMsg);
                }
            });
    }

    const handleSearchColumn1 = (e: string): void => {
        setProductVmcLinks([]);
        searchData["locationSelection"] = e;
        setSearchData(searchData);
        handleChange();
    }

    const handleSearchColumn2 = (e: string): void => {
        setProductVmcLinks([]);
        searchData["productName"] = e;
        setSearchData(searchData);
        handleChange();
    }

    const handleRefreshButton = (): void => {
        setProductVmcLinks([]);
        formData === "" ? setFormData(" ") : setFormData("");
    }

    let timer: NodeJS.Timeout | null = null;

    let newLink: ProductVmcLink = {
        vmcId: 0,
        productId: 0,
        fillCount: null,
        inStockCount: null,
        inStockMinimum: null,
        locationSelection: "",
        motorSpeed: null,
        motorStopDelay: null,
        productName: "",
        selectionDescription: "",
        propertyNames: {
            "locationSelection": {
                sortOrderLevel: 1,
                name: "Selectie",
                editType: "string",
                valPattern: "[^\\\\x00\\\\x7F\\\\/]{0,200}",
                isEditable: true,
                roleName: "",
                hideOnNull: false
            },
            "selectionDescription": {
                "sortOrderLevel": 2,
                "name": "Selectienaam",
                "editType": "string",
                "valPattern": "[^\\\\x00\\\\x7F\\\\/]{0,1000}",
                isEditable: true,
                roleName: "",
                hideOnNull: false
            },
            "productName": {
                "sortOrderLevel": 3,
                "name": "Product",
                "editType": "",
                "valPattern": "",
                isEditable: false,
                roleName: "",
                hideOnNull: false
            },
            "inStockCount": {
                "sortOrderLevel": 4,
                "name": "Actuele voorraad",
                "editType": "string",
                "valPattern": "[0-9]{0,99}",
                isEditable: true,
                roleName: "",
                hideOnNull: false
            },
            "inStockMinimum": {
                "sortOrderLevel": 5,
                "name": "Min. vooraad",
                "editType": "string",
                "valPattern": "[0-9]{0,99}",
                isEditable: true,
                roleName: "",
                hideOnNull: false
            },
            "fillCount": {
                "sortOrderLevel": 6,
                "name": "Waarde na vullen",
                "editType": "string",
                "valPattern": "[0-9]{0,99}",
                isEditable: true,
                roleName: "",
                hideOnNull: false
            },
            "motorStopDelay": {
                "sortOrderLevel": 7,
                "name": "Nalooptijd",
                "editType": "string",
                "valPattern": "[0-9]{0,99}",
                isEditable: true,
                roleName: "",
                hideOnNull: false
            },
            "motorSpeed": {
                "sortOrderLevel": 8,
                "name": "Snelheid",
                "editType": "string",
                "valPattern": "[0-9]{0,99}",
                isEditable: true,
                roleName: "",
                hideOnNull: false
            }
        }        
    };

    const handleChange = () => {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(submitSearch, 2000);
        setCurrentPage(0);
    }

    const submitSearch = () => {
        formData === "" ? setFormData(" ") : setFormData("");
    }

    const init = async () => {
        if (vmc != null) {
            handlePaging(0);
            initSearchFields();
        }
    };

    const getVmcData = async () => {
        const json = await localStorage.getItem('Vmc') ?? "";
        const item = await JSON.parse(json);
        await setVmc(item);
    }

    useEffect(() => {       
        if (vmc == null) {
            getVmcData();            
        }        

        if (productVmcLinks?.length == 0) {
            init();
        }
    }, [getVmcData]);

    return (
        <>
            <div className="">
                <NotImplementedWarningModal
                    showModal={showModal}
                    handleClose={() => {
                        setShowModal(false);
                    }}
                />
            </div>
            <Row className="">
                <Col className="">
                    <Card className="rounded">
                        <Card.Body className="">
                            <form>
                                <Row className="d-none d-md-block">
                                    <Col>&nbsp;</Col>
                                </Row>
                                <Row className="d-none d-md-block">
                                    <Col>&nbsp;</Col>
                                </Row>
                                <Row className="">
                                    <Col sm="12" className="">
                                        <h4 className="mb-2 qvend-title">Automaatdetails - producten koppelen</h4>
                                    </Col>
                                </Row>
                                <Row className="">
                                    <Col sm="12" xs="12" className="mt-2">
                                        <RefreshPull isRefreshing={isRefreshing} onSetPage={handlePaging} />
                                        <div className="table-responsive-lg" style={{ overflowX: 'hidden' }}>
                                            <div>
                                                <Row className="qvend-table-font" style={{ background: "#2b2e83", color: "#FFFFFF", overflowY: "scroll" }}>
                                                    <Col md={6} xs={8} className="qvend-table table-column-first">Selectie</Col>
                                                    <Col md={6} xs={4} className="qvend-table">Product</Col>
                                                </Row>
                                            </div>
                                            <div className="vertical-scrollable" style={{ overscrollBehaviorY: 'contain' }}>
                                                <Row className={("qvend-table-font")} style={{ background: "#cfd5ea", color: "black" }}>
                                                    <Col md={6} xs={8} className="qvend-table search-in-row-first"><SearchInput className="form-control name" type="search" onChange={handleSearchColumn1} /></Col>
                                                    <Col md={6} xs={4} className="qvend-table search-in-row-padding"><SearchInput className="form-control chargeAmount" type="search" onChange={handleSearchColumn2} /></Col>
                                                </Row>
                                                {productVmcLinks.map((prod: ProductVmcLink, index) => (
                                                    // TODO: Link to page to edit selection
                                                    <Link to="/dashboard/details/vmc/product-vmc-link" onClick={() => setProductVmcLinkData(prod)} key={prod.productId + (prod.locationSelectionPrevious ?? '')}>
                                                        <ProductVmcLinkItem
                                                            key={prod.productId + (prod.locationSelectionPrevious ?? '')}
                                                            product={prod}
                                                            rowColor={index % 2 ? "#cfd5ea" : "#e9ebf5"}
                                                        />
                                                    </Link>
                                                ))}                                                
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <ListPagination
                                    articlesCount={productVmcLinksCount}
                                    currentPage={currentPage}
                                    onSetPage={handlePaging}
                                    onRefresh={handleRefreshButton} />
                                <Row style={{ paddingTop: "12px" }}>
                                    <Col>
                                    </Col>
                                    <Col className="text-end">                                        
                                    </Col>
                                </Row>                                
                                <Row style={{ paddingTop: "3px" }}>
                                    <Col>
                                        <button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px', backgroundColor: "red" }} onClick={() => navigateBack() }>Terug</button>                                        
                                    </Col>
                                    <Col className="text-end">                                        
                                        <button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px' }} onClick={() => { let prod = newLink; newLink.vmcId = vmc!.id; setProductVmcLinkData(prod); navigate('/dashboard/details/vmc/product-vmc-link'); }}>+</button>
                                    </Col>
                                </Row>
                                <Row className="d-none errorMsg">
                                    <Col>
                                        {
                                            errorMsg.map((item, index) => {
                                                return <Alert key={index} variant="warning" className="alert" role="alert">
                                                    <span>{item}</span>
                                                </Alert>;
                                            })
                                        }
                                    </Col>
                                </Row>
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default VmcLinkedProductDetails;