import {
    Profit,
    OpenAPI,
    ProfitService
} from '../openapi';
import { ApiResult } from '../openapi/core/ApiResult';

const { getProfitView } = ProfitService;

OpenAPI.BASE = process.env.REACT_APP_API_ENDPOINT as string;

export const getProfit = async (token: string, range: string, filter?: Record<string, string>, search?: Record<string, string>) => {
    const profit: ApiResult = await getProfitView(token, range, filter, search);
    return profit;
};