import React from 'react';
import { Alert } from '../../services/openapi'
import { ApiError } from '../../services/openapi';
import { Col, Row } from 'react-bootstrap';

type AlertProps = {    
    alert: Alert;
    rowColor: string;
    className?: string;
};

const AlertItem = ({ alert, rowColor, className }: AlertProps) => {
    return (
        <>
            <Row className={("qvend-table-font " + (className ?? ""))} style={{ background: (alert.status == 0 ? "red" : alert.status == 1 && alert.endDateTime == null ? "orange" : rowColor), color: "black" }}>
                <Col xl={8} lg={8} md={6} sm={4} xs={4} className="qvend-table table-column-first">{alert.alertTypeDescription}</Col>
                <Col xl={2} lg={2} md={3} sm={4} xs={4} className="qvend-table">{alert.startDateTime}</Col>
                <Col xl={2} lg={2} md={3} sm={4} xs={4} className="qvend-table">{alert.endDateTime}</Col>
            </Row>
        </>
    );
};

export default AlertItem;
