import React from 'react';
import { Profit } from '../../services/openapi'
import { ApiError } from '../../services/openapi';
import { Row, Col, Button } from 'react-bootstrap'


type ProfitItemProps = {
    profit: Profit;
    rowColor: string;
};

const ProfitItem = ({ profit, rowColor }: ProfitItemProps) => {
    return (
        <>
            <Row key={profit.period} className="qvend-table-font" style={{ background: rowColor, color: "black" }}>
                <Col md={5} xs={3} className="qvend-table table-column-first description-column">{profit.period}</Col>
                <Col md={2} xs={3} className="qvend-table amount-column">{profit.succeeded}</Col>
                <Col md={2} xs={3} className="qvend-table amount-column">{profit.failed}</Col>
                <Col md={3} xs={3} className="qvend-table amount-column">{(profit.profitInCents/100).toLocaleString("nl-nl", { style: "currency", currency: "EUR" })}</Col>
            </Row>
        </>
    );
};

export default ProfitItem;
