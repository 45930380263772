import React, { ChangeEventHandler} from 'react';
import { ApiError } from '../../services/openapi';
import { Col, Form, Row } from 'react-bootstrap';

type ItemLinkerProps = {
    error?: ApiError;
    itemId: number;
    itemNameColumn1: string;
    itemNameColumn2?: string | null;
    isDisabled?: boolean | undefined;
    isChecked: boolean;
    rowColor: string;
    className?: string;
    title?: string | null;
    handleChange: ChangeEventHandler<HTMLInputElement>;
};

const ItemLinker = ({ itemId, itemNameColumn1, itemNameColumn2, isDisabled, isChecked, rowColor, className, title, handleChange }: ItemLinkerProps) => {    
    return (
        <>
            <Row className={("qvend-table-font " + (className ?? ""))} style={{ background: rowColor, color: "black" }} title={title != null ? title : itemId.toString()}>
                <Col className="qvend-table table-column-first q-vend-detail-table-col">{itemNameColumn1}</Col>
                {
                    itemNameColumn2 != null &&
                    (<Col xl={4} lg={4} md={4} sm={4} xs={4} className="qvend-table table-column-first q-vend-detail-table-col">{itemNameColumn2}</Col>)
                    
                }
                <Col xl={4} lg={4} md={4} sm={4} xs={4} className="qvend-table q-vend-detail-table-col">{
                    <span>
                        <Form.Check                            
                            onChange={(e) => { handleChange(e); }}                            
                            disabled={isDisabled}
                            checked={isChecked} /></span>
                }
                </Col>
            </Row>
        </>
    );
};

export default ItemLinker;
