import React from 'react';
import { VmcStatusDto as VmcStatus } from '../../services/openapi'
import { ApiError } from '../../services/openapi';
import { Col, Row } from 'react-bootstrap';

type VmcStatusItemProps = {    
    vmcStatus: VmcStatus;
    rowColor: string;
    className?: string;
};

const VmcStatusItem = ({ vmcStatus, rowColor, className }: VmcStatusItemProps) => {
    return (
        <>
            <Row className={("qvend-table-font " + (className ?? ""))} style={{ background: (vmcStatus.isConnected ? rowColor : "orange"), color: "black" }}>
                <Col xl={2} lg={2} md={3} sm={6} xs={6} className="qvend-table table-column-first">{vmcStatus.description}</Col>
                <Col xl={2} lg={2} md={3} sm={0} xs={0} className="qvend-table d-none d-md-block">{vmcStatus.lastMessageReceivedDateTime}</Col>
                <Col xl={8} lg={8} md={6} sm={6} xs={6} className="qvend-table">{vmcStatus.locationDescription}</Col>
            </Row>
        </>
    );
};

export default VmcStatusItem;
