import React, { useCallback, useEffect, useState, useRef, RefObject } from 'react'
import { Row, Col, Alert, Form, FormCheck, Badge, CloseButton } from 'react-bootstrap'
import Card from '../../../components/Card'

import { AddTx, TxItem } from '../../../features/transactions';
import { getTransactions, addTransaction, updateTransaction, deleteTransaction } from '../../../services/api/transaction';
import { ApiError, Transaction } from '../../../services/openapi';

import { useKeycloak } from '@react-keycloak/web';

import SearchInput from '../../../components/SearchInput';
import ListPagination from '../../../components/ListPagination';
import RefreshPull from '../../../components/RefreshPull';
import CsvExport from '../../../components/exports/CsvExport';
import { ApiResult } from '../../../services/openapi/core/ApiResult';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { Link } from 'react-router-dom';

const Transactions = () => {
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [csvExport, setCsvExport] = useState<Transaction[]>([]);            
    const [transactionCount, setTransactionCount] = useState<string>("0");    
    const [pagingString, setPagingString] = useState<string>("0-25");
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
    const [formData, setFormData] = useLocalStorage<string>("formData", "");
    const [searchData, setSearchData] = useLocalStorage<Record<string, string>>("searchData", {});
    const [filterData, setFilterData] = useLocalStorage<string>("filterData", "");
    const [currentPage, setCurrentPage] = useLocalStorage<number>("currentPage", 0);
    const [siteFilter, setSiteFilter] = useLocalStorage<number[]>("siteFilter", []);
    const [siteFilterQuery, setSiteFilterQuery] = useLocalStorage<string>("siteFilterQuery", "");
    const [errorMsg, setErrorMsg] = useState<string[]>([]);

    const { keycloak } = useKeycloak();
    const token = keycloak.token || '';

    const delay = async (ms: number) => {
        await new Promise(resolve => setTimeout(resolve, ms));
    }

    const csvHeaders = [
        { label: 'Site ID', key: 'siteId'},
        { label: 'POI', key: 'terminalIdentifier' },
        { label: 'Terminal', key: 'terminalDescription'},
        { label: 'Datum en tijd', key: 'modified' },        
        { label: 'Bedrag', key: 'chargedAmount' },
        { label: 'Foutcode', key: 'terminalStatusCode' },
        { label: 'Product', key: 'productId' },
        { label: 'Merk kaart', key: 'paymentMethod' }
    ];

    let timer: NodeJS.Timeout | null = null;

    const submitSearch = () => {
        formData === "" ? setFormData(" ") : setFormData("");
    }

    const setData = (data: Transaction) => {
        localStorage.setItem('Transaction', JSON.stringify(data));
    }

    const handleChange = () => {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(submitSearch, 2000);
        setCurrentPage(0);
    }

    const handleFilter = (e: string): void => {
        setTransactions([]);
        setFilterData(e);
        setCurrentPage(0);
    }

    const handleSearch = (e: string): void => {
        setTransactions([]);
        searchData["search"] = e;
        setSearchData(searchData);
        formData === "" ? setFormData(" ") : setFormData("");
        setCurrentPage(0);
    }

    const handleSearchColumnModified = (e: string): void => {
        setTransactions([]);
        searchData["modified"] = e;
        setSearchData(searchData);
        handleChange();
    }

    const handleSearchColumnDeliveryStatus = (e: string): void => {
        setTransactions([]);
        searchData["deliveryStatus"] = e;
        setSearchData(searchData);
        handleChange();
    }

    const handleSearchColumnProduct = (e: string): void => {
        setTransactions([]);
        searchData["productId"] = e;
        setSearchData(searchData);
        handleChange();
    }

    const handleSearchColumnVmc = (e: string): void => {
        setTransactions([]);
        searchData["vmcName"] = e;
        setSearchData(searchData);
        handleChange();
    }

    const handleSearchColumnAmount = (e: string): void => {
        setTransactions([]);
        searchData["chargedAmountInCents"] = e.replace('.', ',');
        setSearchData(searchData);
        handleChange();
    }

    const handleRefreshButton = (): void => {
        setTransactions([]);
        formData === "" ? setFormData(" ") : setFormData("");
    }

    const handlePaging = async (e: number) => {
        errorMsg.length = 0;

        do {
            await delay(1000);
        } while (isRefreshing);

        setCurrentPage(e);

        const start = ((e + 1) * 25) - 25;
        const end = ((e + 1) * 25);
        const rangeString = `${start}-${end}`
        setPagingString(rangeString);

        document.querySelector('#refresh')?.classList.remove("d-none");
        setIsRefreshing(true);

        getTransactions(keycloak.token || '', rangeString, { search: formData, status: filterData, sites: siteFilter.join(), sitesQuery: siteFilterQuery }, searchData)
            .then((result) => {
                setTransactions(result.body);
                var totalCount = result.header.split('/')[1];
                setTransactionCount(totalCount)
                document.querySelector('#refresh')?.classList.add("d-none");
                setIsRefreshing(false);
            })
            .catch((error) => {
                try {
                    document.querySelector('.errorMsg')?.classList.remove("d-none");
                    Object.entries<Record<string, any>>(JSON.parse(error.body)["errors"]).forEach((k) => { k[1].forEach((msg: string) => { errorMsg.push(msg); setErrorMsg(errorMsg); }) });
                } catch (ex) {
                    errorMsg.push("Error handling response");
                    setErrorMsg(errorMsg);
                }

                document.querySelector('#refresh')?.classList.add("d-none");
                setIsRefreshing(false);
            });
    }

    const handleCsvExport = (): Promise<ApiResult> => {
        const rangeString = "0";
        return getTransactions(keycloak.token || '', rangeString, { search: formData, status: filterData, export: "", sites: siteFilter.join(), sitesQuery: siteFilterQuery }, searchData);
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>): boolean => {
        if (event.keyCode == 13) {
            event.preventDefault();
            return false;
        }
        return true;
    }

    const init = () => {
        document.querySelector('.errorMsg')?.classList.add("d-none");
        handlePaging(currentPage ?? 0);
        initSearchFields();
    };

    const initSearchFields = () => {
        Object.keys(searchData).map(x => {
            const search: HTMLInputElement | null = document.querySelector(`.${x}`) as HTMLInputElement;
            if (search) {
                search.value = searchData[x];
            }
        })
    }

    const onResize = () => {
        var e = document.querySelector(".vertical-scrollable") as HTMLElement;
        var h = window.innerHeight;
        e.style.height = (h - 284) + "px";
        e.style.maxHeight = (h - 280) + "px";
    };

    useEffect(() => {
        window.addEventListener("resize", onResize);

        if (transactions?.length == 0) {
            init();
            onResize();
        }

        return () => {
            window.removeEventListener("resize", onResize);
        };

    }, [formData, filterData]);

    return (
        <>
            <Row>
                <Col className="">
                    <Card className="rounded">
                        <Card.Body className="">
                            <Row className="d-none d-md-block">
                                <Col>&nbsp;</Col>
                            </Row>
                            <Row className="d-none d-md-block">
                                <Col>&nbsp;</Col>
                            </Row>
                            <Row>
                                <Col className="">
                                    <h4 className="mb-2 qvend-title">Transacties</h4>
                                </Col>
                            </Row>
                            <Row style={{ fontSize: '11px' }} >
                                <Col xs={5} sm={5} md={5} style={{ paddingRight: 0 }}>
                                    <div className="bd-example">
                                        <div>
                                            <SearchInput className="form-control search" type="search" placeholder="Zoek.." onChange={handleSearch} />
                                        </div>
                                    </div>
                                </Col>
                                <Col md={1} xs={1}>
                                    <button type="button" className="btn btn-qvend" style={{ fontSize: '11px', padding: '0.5rem 0.3rem' }}>...</button>
                                </Col>
                                <Col className="text-end">
                                    <CsvExport onGetData={handleCsvExport} headers={csvHeaders} separator={";"} filename={"betalingen.csv"} className="btn btn-qvend" style={{ fontSize: '11px' }} />
                                </Col>
                            </Row>
                            <form className="" onKeyDown={handleKeyDown}>
                                <Row>
                                    <Col md={12} xs={10} style={{ paddingRight: 0 }}>
                                        <div>
                                            <fieldset className="form-control-sm" style={{ fontSize: '11px' }}>
                                                <Form.Check className="form-check-inline">
                                                    <FormCheck.Input onChange={() =>
                                                        handleFilter("")
                                                    } type="radio" name="radios" className="form-check-input" id="radio1" defaultChecked={filterData === ""} />
                                                    <FormCheck.Label className="form-check-label" htmlFor="radio1">Allemaal</FormCheck.Label>
                                                </Form.Check>
                                                <Form.Check className="form-check-inline">
                                                    <FormCheck.Input onChange={() =>
                                                        handleFilter("0")
                                                    } type="radio" name="radios" className="form-check-input" id="radio2" defaultChecked={filterData === "0"}/>
                                                    <FormCheck.Label className="form-check-label" htmlFor="radio2">Wacht op betaling</FormCheck.Label>
                                                </Form.Check>
                                                <Form.Check className="form-check-inline">
                                                    <FormCheck.Input onChange={() =>
                                                        handleFilter("2")
                                                    } type="radio" name="radios" className="form-check-input" id="radio3" defaultChecked={filterData === "2"}/>
                                                    <FormCheck.Label className="form-check-label" htmlFor="radio3">Reservering geslaagd</FormCheck.Label>
                                                </Form.Check>
                                                <Form.Check className="form-check-inline">
                                                    <FormCheck.Input onChange={() =>
                                                        handleFilter("5")
                                                    } type="radio" name="radios" className="form-check-input" id="radio3" defaultChecked={filterData === "5"} />
                                                    <FormCheck.Label className="form-check-label" htmlFor="radio3">Levering bezig</FormCheck.Label>
                                                </Form.Check>
                                                <Form.Check className="form-check-inline">
                                                    <FormCheck.Input onChange={() =>
                                                        handleFilter("10")
                                                    } type="radio" name="radios" className="form-check-input" id="radio3" defaultChecked={filterData === "10"} />
                                                    <FormCheck.Label className="form-check-label" htmlFor="radio3">Levering gereed</FormCheck.Label>
                                                </Form.Check>
                                                <Form.Check className="form-check-inline">
                                                    <FormCheck.Input onChange={() =>
                                                        handleFilter("20")
                                                    } type="radio" name="radios" className="form-check-input" id="radio3" defaultChecked={filterData === "20"} />
                                                    <FormCheck.Label className="form-check-label" htmlFor="radio3">Betaling afgerond</FormCheck.Label>
                                                </Form.Check>
                                                <Form.Check className="form-check-inline">
                                                    <FormCheck.Input onChange={() =>
                                                        handleFilter("25")
                                                    } type="radio" name="radios" className="form-check-input" id="radio3" defaultChecked={filterData === "25"} />
                                                    <FormCheck.Label className="form-check-label" htmlFor="radio3">Betaling mislukt</FormCheck.Label>
                                                </Form.Check>
                                                <Form.Check className="form-check-inline">
                                                    <FormCheck.Input onChange={() =>
                                                        handleFilter("30")
                                                    } type="radio" name="radios" className="form-check-input" id="radio3" defaultChecked={filterData === "30"} />
                                                    <FormCheck.Label className="form-check-label" htmlFor="radio3">Transactie mislukt</FormCheck.Label>
                                                </Form.Check>
                                                <Form.Check className="form-check-inline">
                                                    <FormCheck.Input onChange={() =>
                                                        handleFilter("99")
                                                    } type="radio" name="radios" className="form-check-input" id="radio3" defaultChecked={filterData === "99"} />
                                                    <FormCheck.Label className="form-check-label" htmlFor="radio3">Transactie geslaagd</FormCheck.Label>
                                                </Form.Check>
                                                {(siteFilter && siteFilter.length > 0 || siteFilterQuery && siteFilterQuery.length > 2) ? <span className="text-end">
                                                    <Badge pill bg="dark" className="p-1 " onClick={() => { setSiteFilter([]); setSiteFilterQuery(""); setTransactions([]); setCurrentPage(0); formData === "" ? setFormData(" ") : setFormData(""); window.localStorage.removeItem("searchDataSites") }}><CloseButton variant="white" /> Sites</Badge></span> : null}
                                            </fieldset>
                                        </div>                                        
                                    </Col>
                                </Row>
                            </form>
                            <Row className="">
                                <Col sm="12" xs="12" className="mt-2">
                                    <RefreshPull isRefreshing={isRefreshing} onSetPage={handlePaging} />
                                    <div className="table-responsive-lg" style={{ overflowX: 'hidden' }}>
                                        <div>
                                            <Row className="qvend-table-font" style={{ background: "#2b2e83", color: "#FFFFFF", overflowY: "scroll" }}>
                                                <Col xl={2} lg={2} md={3} sm={5} xs={5} className="qvend-table table-column-first">Tijd</Col>                                                
                                                <Col xl={3} lg={3} md={2} sm={0} xs={0} className="qvend-table d-none d-md-block">VMC</Col>
                                                <Col xl={3} lg={3} md={2} sm={4} xs={4} className="qvend-table">Status</Col>
                                                <Col xl={3} lg={3} md={3} sm={0} xs={0} className="qvend-table d-none d-md-block">Product</Col>
                                                <Col xl={1} lg={1} md={2} sm={3} xs={3} className="qvend-table">Bedrag</Col>
                                            </Row>
                                        </div>
                                        <div className="vertical-scrollable" style={{ overscrollBehaviorY: 'contain' }}>
                                            <Row className={("qvend-table-font")} style={{ background: "#cfd5ea", color: "black" }}>
                                                <Col xl={2} lg={2} md={3} sm={5} xs={5} className="qvend-table search-in-row-first"><SearchInput disabled className="form-control modified" type="search" onChange={handleSearchColumnModified} /></Col>                                                
                                                <Col xl={3} lg={3} md={2} sm={0} xs={0} className="qvend-table search-in-row-padding d-none d-md-block"><SearchInput className="form-control vmcName" type="search" onChange={handleSearchColumnVmc} /></Col>
                                                <Col xl={3} lg={3} md={2} sm={4} xs={4} className="qvend-table search-in-row-padding"><SearchInput className="form-control deliveryStatus" type="search" onChange={handleSearchColumnDeliveryStatus} /></Col>
                                                <Col xl={3} lg={3} md={3} sm={0} xs={0} className="qvend-table search-in-row-padding d-none d-md-block"><SearchInput className="form-control productId" type="search" onChange={handleSearchColumnProduct} /></Col>
                                                <Col xl={1} lg={1} md={2} sm={3} xs={3} className="qvend-table search-in-row-padding"><SearchInput className="form-control align-right chargedAmountInCents" type="search" onChange={handleSearchColumnAmount} /></Col>
                                            </Row>
                                            <Row className="d-none errorMsg">
                                                <Col>
                                                    {
                                                        errorMsg.map((item, index) => {
                                                            return <Alert key={index} variant="warning" className="alert" role="alert">
                                                                <span>{item}</span>
                                                            </Alert>;
                                                        })
                                                    }
                                                </Col>
                                            </Row>
                                            {transactions.map((tx: Transaction, index) => (
                                                <Link to="/dashboard/details/transaction" onClick={() => setData(tx)} key={tx.id}>
                                                <TxItem                                                    
                                                    key={tx.id}
                                                    tx={tx}                                                    
                                                    rowColor={index % 2 ? "#cfd5ea" : "#e9ebf5"}
                                                    />
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <ListPagination
                                articlesCount={transactionCount}
                                currentPage={currentPage}
                                onSetPage={handlePaging}
                                onRefresh={handleRefreshButton} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default Transactions
