import React, { useEffect, useState } from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./Keycloack";
import { AuthClientEvent } from "@react-keycloak/core";
import Nav from "./components/Nav";
import IndexRouters from "./router/index"
import { useNavigate } from "react-router-dom";

import { IdleTimeOutModal } from './components/IdleTimeoutModal'

import "./assets/scss/hope-ui.scss"
import "./assets/scss/dark.scss"
import "./assets/scss/rtl.scss"
import "./assets/scss/custom.scss"
import "./assets/scss/customizer.scss"


function App() {
    const [showModal, setShowModal] = useState(false);
    const [showButton, setShowButton] = useState(true);
    const [lastUserActionTime, setLastUserActionTime] = useState<Date>(new Date());
    const navigate = useNavigate();    

    useEffect(() => {
        window.addEventListener("click", refreshLastUserActionTime);
        
        return () => {
            window.removeEventListener("click", refreshLastUserActionTime);
        };

    }, []);    

    const refreshLastUserActionTime = () => {
        setLastUserActionTime(new Date())
    }

    const onKeycloakEvent = (event: AuthClientEvent, error: any) => {
        if (event === 'onReady') {
            return;
        }

        if (error) {
            console.log('onKeycloakEvent', error)
        }

        if (event === 'onAuthSuccess') {
            navigate('dashboard');
        }

        if (event === 'onAuthRefreshError' || event === 'onAuthError') {
            keycloak.clearToken();
            keycloak.logout();
        }

        if (event === 'onTokenExpired') {
            if (showModal) {
                keycloak.clearToken();
                keycloak.logout();
            }

            const currentTime = new Date().getTime();
            if (currentTime - lastUserActionTime.getTime() > 300000) {
                setShowButton(true);
                setShowModal(true);
            }

            keycloak.updateToken(1).then(function (refreshed: boolean) {
                if (refreshed) {
                    console.log('Token refreshed');
                } else {
                    console.log('Token still valid');
                }
            }).catch(function () {
                console.log('Token refresh failed');
            });
        }
    }

    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
                <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                </symbol>
                <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path>
                </symbol>
                <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                </symbol>
                <symbol id="info-lamp" fill="none" viewBox="0 3 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-bulb">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M3 12h1m8 -9v1m8 8h1m-15.4 -6.4l.7 .7m12.1 -.7l-.7 .7" />
                    <path d="M9 16a5 5 0 1 1 6 0a3.5 3.5 0 0 0 -1 3a2 2 0 0 1 -4 0a3.5 3.5 0 0 0 -1 -3" />
                    <path d="M9.7 17l4.6 0" />
                </symbol>
            </svg>
            <div className="">
                <IdleTimeOutModal
                    showModal={showModal}
                    showButton={showButton}
                    handleClose={() => {
                        refreshLastUserActionTime();
                        setShowModal(false);
                    }}
                />
            </div>
            <div className="App main-content" onKeyPress={() => refreshLastUserActionTime()}>
                <ReactKeycloakProvider authClient={keycloak} onEvent={onKeycloakEvent} initOptions={{ onLoad: 'login-required' }}>
                    <Nav />
                    <IndexRouters />
                </ReactKeycloakProvider>
            </div>
        </>
    );
}

export default App;