import {
    Terminal,
    OpenAPI,
    TerminalsService
} from '../openapi';
import { ApiResult } from '../openapi/core/ApiResult';

const { post, put, get, get1, getTotalCount, getOnlineCount, delete: del, ctmp, conf, getTxCountPerTerminal } = TerminalsService;

OpenAPI.BASE = process.env.REACT_APP_API_ENDPOINT as string;

export const getTerminals = async (token: string, range: string, filter?: Record<string, string>, search?: Record<string, string>) => {
    const terminals: ApiResult = await get(token, range, filter, search);
    return terminals;
};

export const getTxCount = async (token: string, range: string, filter?: Record<string, string>, search?: Record<string, string>) => {
    const terminals: ApiResult = await getTxCountPerTerminal(token, range, filter, search);
    return terminals;
};

export const getTerminalCount = async (token: string, filter?: Record<string, string>, search?: Record<string, string>): Promise<string> => {
    const result: string = await getTotalCount(token, filter, search);
    const count = result.split('/')[1];
    return count;
};

export const getOnlineTerminalsCount = async (token: string): Promise<string> => {
    const result: string = await getOnlineCount(token);
    const count = result.split('/')[1];
    return count;
};

export const getTerminalById = async (token: string, id: number): Promise<Terminal> => {
    return await get1(token, id);
};

export const addTerminal = async (token: string, newTerminal: Terminal): Promise<Terminal> => {
    return await post(token, newTerminal);
};

export const updateTerminal = async (
    token: string,
    id: number,
    terminal: Terminal
): Promise<Terminal> => {
    const t = { ...terminal };
    t.propertyNames = {};
    return await put(token, id, t);
};

export const requestCtmp = async (
    token: string,
    id: number
) => {
    await ctmp(token, id);
};

export const configure = async (
    token: string,
    id: number,
    swapCode : string
) => {
    await conf(token, id, swapCode);
};


export const deleteTerminal = async (token: string, id: number) => {
    await del(token, id);
};
