import React, { useState, useEffect, ChangeEvent, MouseEventHandler } from 'react';
import { Row, Col, Alert } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { DetailItem } from '../../../../features/details';
import { updateAlertDefinition } from '../../../../services/api/alertDefinition';
import { ApiError, AlertDefinition } from '../../../../services/openapi';
import { useKeycloak } from '@react-keycloak/web'
import { PropertyInfo } from '../../../../services/openapi/models/PropertyInfo';
import { Link, useNavigate } from "react-router-dom";
import { NotImplementedWarningModal } from '../../../../components/NotImplementedWarningModal';

const AlertDefinitionDetails = () => {
    const [alertDefinition, setAlertDefinition] = useState<any>();
    const [showInputEle, setShowInputEle] = useState<boolean>(false);
    const [showModal, setShowModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string[]>([]);

    const { keycloak } = useKeycloak();
    const token = keycloak.token || '';

    const navigate = useNavigate();

    const setAlertDefinitionData = (data: AlertDefinition) => {
        localStorage.setItem('AlertDefinition', JSON.stringify(data));
    }

    useEffect(() => {
        if (alertDefinition == null) {
            const json = localStorage.getItem('AlertDefinition') ?? "";
            const item = JSON.parse(json);

            setAlertDefinition(item);
        }
    }, []);

    function handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
        e.preventDefault();

        document.querySelector('.errorMsg')?.classList.add("d-none");
        errorMsg.length = 0;

        if (e.currentTarget.checkValidity()) {
            let alrt: AlertDefinition = alertDefinition;
            var update: AlertDefinition = {
                id: alrt.id,
                alertTypeDescription: alrt.alertTypeDescription,
                alertTypeId: alrt.alertTypeId,
                usernameLastModification: keycloak.idTokenParsed?.preferred_username ?? '?',
                confirmationEndsAlert: alrt.confirmationEndsAlert,
                instruction: alrt.instruction,
                recipients: alrt.recipients,
                sendMail: alrt.sendMail,
                siteId: alrt.siteId,
                subject: alrt.subject
            };

            updateAlertDefinition(token, alrt.id ?? 0, update).catch((error: ApiError) => {
                document.querySelector('.errorMsg')?.classList.remove("d-none");
                Object.entries<Record<string, any>>(JSON.parse(error.body)["errors"]).forEach((k) => {
                    k[1].forEach((msg: string) => { errorMsg.push(msg); setErrorMsg(errorMsg); });
                    setShowInputEle(true);
                })
            }).then(a => {
                if (a !== undefined) {
                    setAlertDefinitionData(a);
                    setAlertDefinition(a);
                }
            }).catch((error: ApiError) => {
                try {
                    document.querySelector('.errorMsg')?.classList.remove("d-none");
                    Object.entries<Record<string, any>>(JSON.parse(error.body)["errors"]).forEach((k) => { k[1].forEach((msg: string) => { errorMsg.push(msg); setErrorMsg(errorMsg); }) });
                } catch (ex) {
                    errorMsg.push("Error handling response");
                    setErrorMsg(errorMsg);
                }
            });

            setShowInputEle(!showInputEle);
        }
    }    

    return (
        <>
            <div className="">
                <NotImplementedWarningModal
                    showModal={showModal}
                    handleClose={() => {
                        setShowModal(false);
                    }}
                />
            </div>
            <Row className="">
                <Col className="">
                    <Card className="rounded">
                        <Card.Body className="">
                            <form onSubmit={e => handleSubmit(e)} onChange={() => setErrorMsg([])}>
                                <Row className="d-none d-md-block">
                                    <Col>&nbsp;</Col>
                                </Row>
                                <Row className="d-none d-md-block">
                                    <Col>&nbsp;</Col>
                                </Row>
                                <Row className="">
                                    <Col sm="12" className="">
                                        <h4 className="mb-2 qvend-title">Alarminstellingdetails</h4>
                                    </Col>
                                </Row>
                                <Row className="">
                                    <Col sm="12" xs="12" className="mt-2">
                                        <div className="table-responsive-lg" style={{ overflowX: 'hidden' }}>
                                            <div>
                                                {alertDefinition && Object.entries<PropertyInfo>(alertDefinition).filter(([key, value]) => !(key === "poi" && value === null) && !(key === "vmcIdentifier" && value === null) && alertDefinition["propertyNames"][key] !== undefined && (alertDefinition["propertyNames"][key].editType !== '' || alertDefinition["propertyNames"][key].editType === '' && value !== null)).sort((a, b) => alertDefinition["propertyNames"][a[0]].sortOrderLevel - alertDefinition["propertyNames"][b[0]].sortOrderLevel).map(([key, value], index) => (
                                                    <DetailItem key={index} propertyName={alertDefinition["propertyNames"][key].name} defaultValue={value ?? ""} rowColor={index % 2 ? "#cfd5ea" : "#e9ebf5"} editType={alertDefinition["propertyNames"][key].editType} pattern={alertDefinition["propertyNames"][key].valPattern} hideOnNull={alertDefinition["propertyNames"][key].hideOnNull}
                                                        handleDoubleClick={() => setShowInputEle(true)}
                                                        handleChange={(e: ChangeEvent<HTMLInputElement>) => { alertDefinition[key] = (e.target.value); setAlertDefinition(alertDefinition); }} showInputEle={showInputEle}></DetailItem>
                                                ))}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="d-none errorMsg">
                                    <Col>
                                        {
                                            errorMsg.map((item, index) => {
                                                return <Alert key={index} variant="warning" className="alert" role="alert">
                                                    <span>{item}</span>
                                                </Alert>;
                                            })
                                        }
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: "3px" }}>
                                    <Col>
                                        <Link to="/dashboard/lists/alertDefinitions" onClick={() => { }}>
                                            <button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px', backgroundColor: "red" }} onClick={() => { }}>Terug</button>
                                        </Link>
                                    </Col>
                                    <Col className="text-end">
                                        {showInputEle ?
                                            (<button type="submit" className="btn btn-fixed-width" style={{ fontSize: '11px' }}>Opslaan</button>) :
                                            (<button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px' }} onClick={(e) => { e.preventDefault(); setShowInputEle(true); }}>Wijzigen</button>)
                                        }                                        
                                    </Col>
                                </Row>
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default AlertDefinitionDetails;