import {
    VmcStatusDto as VmcStatus,
    OpenAPI,
    VmcsService
} from '../openapi';
import { ApiResult } from '../openapi/core/ApiResult';

const { get, get1, getTotalCount, getOnlineCount, put } = VmcsService;

OpenAPI.BASE = process.env.REACT_APP_API_ENDPOINT as string;

export const getVmcs = async (token: string, range: string, filter?: Record<string, string>, search?: Record<string, string>) => {
    const vmcs: ApiResult = await get(token, range, filter, search);
    return vmcs;
};

export const getVmcCount = async (token: string, filter?: Record<string, string>, search?: Record<string, string>): Promise<string> => {
    const result: string = await getTotalCount(token, filter, search);
    const count = result.split('/')[1];
    return count;
};

export const getOnlineVmcsCount = async (token: string): Promise<string> => {
    const result: string = await getOnlineCount(token);
    const count = result.split('/')[1];
    return count;
};

export const getVmcById = async (token: string, id: number): Promise<VmcStatus> => {
    return await get1(token, id);
};

export const updateVmc = async (
    token: string,
    id: number,
    vmc: VmcStatus
): Promise<VmcStatus> => {
    const v = { ...vmc };
    v.propertyNames = {};
    return await put(token, id, v);
};

