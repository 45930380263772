import {
    AlertDefinition,
    OpenAPI,
    AlertDefinitionService
} from '../openapi';
import { ApiResult } from '../openapi/core/ApiResult';

const { get, get1, put, getTotalCount } = AlertDefinitionService;

OpenAPI.BASE = process.env.REACT_APP_API_ENDPOINT as string;

export const getAlertDefinitions = async (token: string, range: string, filter?: Record<string, string>, search?: Record<string, string>) => {
    const alertDefinitions: ApiResult = await get(token, range, filter, search);
    return alertDefinitions;
};

export const getAlertDefinitionCount = async (token: string, filter?: Record<string, string>, search?: Record<string, string>): Promise<string> => {
    const result: string = await getTotalCount(token, filter, search);
    const count = result.split('/')[1];
    return count;
};

export const getAlertDefinitionById = async (token: string, id: string): Promise<AlertDefinition> => {
    return await get1(token, id);
};

export const updateAlertDefinition = async (
    token: string,
    id: number,
    alertDefinition: AlertDefinition
): Promise<AlertDefinition> => {
    const ad = { ...alertDefinition };
    ad.propertyNames = {};
    return await put(token, id, ad);
};
