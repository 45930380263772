
import React, { ChangeEvent, MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Row, Col, Alert, Form, FormCheck } from 'react-bootstrap';
import Card from '../../../components/Card';

import { SiteItem } from '../../../features/sites';
import { getSites, getSiteCount } from '../../../services/api/site';
import { ApiError, Site } from '../../../services/openapi';

import { useKeycloak } from '@react-keycloak/web';

import SearchInput from '../../../components/SearchInput';
import ListPagination from '../../../components/ListPagination';
import RefreshPull from '../../../components/RefreshPull';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { Link } from 'react-router-dom';

const Sites = () => {
    const [sites, setSites] = useState<Site[]>([]);        
    const [siteCount, setSiteCount] = useState<string>("0");    
    const [pagingString, setPagingString] = useState<string>("0-25");    
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
    const [tableBodyHeight, setTableBodyHeight] = useState<number>(380);
    const [formData, setFormData] = useLocalStorage<string>("formData", "");
    const [searchData, setSearchData] = useLocalStorage<Record<string, string>>("searchDataSites", {});
    const [filterData, setFilterData] = useLocalStorage<string>("filterData", "");
    const [currentPage, setCurrentPage] = useLocalStorage<number>("currentPage", 0);
    const [siteFilter, setSiteFilter] = useLocalStorage<string[]>("siteFilter", []);
    const [siteFilterQuery, setSiteFilterQuery] = useLocalStorage<string>("siteFilterQuery", "");
    const [errorMsg, setErrorMsg] = useState<string[]>([]);

    const { keycloak } = useKeycloak();
    const token = keycloak.token || '';

    const navigate = useNavigate();

    const handleMouseClickOnRow: MouseEventHandler<HTMLElement> = (e) => {
        const el = e.target as HTMLInputElement;

        if (el != null && el.type == 'checkbox') {
            return;
        }

        let path = `/dashboard/details/site`;
        navigate(path);
    }    

    const setData = (data: Site) => {
        localStorage.setItem('Site', JSON.stringify(data));
    }

    const handleFilter = (e: string): void => {
        setSites([]);
        setFilterData(e);
        setCurrentPage(0);
    }

    const handleSelectAll = (e: ChangeEvent<HTMLInputElement>): void => {
        if (e.target.checked) {
            setSiteFilter([]);
            setSiteFilterQuery(JSON.stringify(searchData))
        }
        else {
            setSiteFilterQuery("")
        }
    }

    const handleSearch = (e: string): void => {
        setSites([]);
        searchData["search"] = e;
        setSearchData(searchData);

        if (siteFilterQuery.length > 0) {
            setSiteFilter([]);
            setSiteFilterQuery(JSON.stringify(searchData))
        }

        formData === "" ? setFormData(" ") : setFormData("");       
        setCurrentPage(0);
    }

    const handleSearchColumn1 = (e: string): void => {
        setSites([]);
        searchData["siteId"] = e;
        setSearchData(searchData);

        if (siteFilterQuery.length > 0) {
            setSiteFilter([]);
            setSiteFilterQuery(JSON.stringify(searchData))
        }

        handleChange();
    }

    const handleSearchColumn2 = (e: string): void => {
        setSites([]);
        searchData["description"] = e;
        setSearchData(searchData);

        if (siteFilterQuery.length > 0) {
            setSiteFilter([]);
            setSiteFilterQuery(JSON.stringify(searchData))
        }

        handleChange();
    }

    const handleSearchColumn3 = (e: string): void => {
        setSites([]);
        searchData["customerName"] = e;
        setSearchData(searchData);

        if (siteFilterQuery.length > 0) {
            setSiteFilter([]);
            setSiteFilterQuery(JSON.stringify(searchData))
        }

        handleChange();
    }    

    const handleRefreshButton = (): void => {
        setSites([]);
        formData === "" ? setFormData(" ") : setFormData("");
    }

    const delay = async (ms: number) => {
        await new Promise(resolve => setTimeout(resolve, ms));
    }

    let timer: NodeJS.Timeout | null = null;

    const submitSearch = () => {
        formData === "" ? setFormData(" ") : setFormData("");
    }

    const handleChange = () => {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(submitSearch, 2000);
        setCurrentPage(0);
    }

    const handlePaging = async (e: number) => {
        errorMsg.length = 0;

        do {
            await delay(1000);
        } while (isRefreshing);       

        setCurrentPage(e);

        const start = ((e + 1) * 25) - 25;
        const end = ((e + 1) * 25);
        const rangeString = `${start}-${end}`
        setPagingString(rangeString);

        document.querySelector('#refresh')?.classList.remove("d-none");
        setIsRefreshing(true);        

        getSites(keycloak.token || '', rangeString, { search: formData, Online: filterData }, searchData)
            .then((result) => {
                setSites(result.body);
                var totalCount = result.header.split('/')[1];
                setSiteCount(totalCount);
                document.querySelector('#refresh')?.classList.add("d-none");
                setIsRefreshing(false);
            })
            .catch((error: ApiError) => {
                try {
                    document.querySelector('.errorMsg')?.classList.remove("d-none");
                    Object.entries<Record<string, any>>(JSON.parse(error.body)["errors"]).forEach((k) => { k[1].forEach((msg: string) => { errorMsg.push(msg); setErrorMsg(errorMsg); }) });
                } catch (ex) {
                    console.log(ex);
                    errorMsg.push("Error handling response");
                    setErrorMsg(errorMsg);
                }

                document.querySelector('#refresh')?.classList.add("d-none");
                setIsRefreshing(false);
            });
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>): boolean => {
        if (event.keyCode == 13) {
            event.preventDefault();
            return false;
        }
        return true;
    }

    const init = () => {
        document.querySelector('.errorMsg')?.classList.add("d-none");
        handlePaging(currentPage ?? 0);
        initSearchFields();
    };

    const initSearchFields = () => {
        Object.keys(searchData).map(x => {
            const search: HTMLInputElement | null = document.querySelector(`.${x}`) as HTMLInputElement;
            if (search) {
                search.value = searchData[x];
            }
        })
    }

    const onResize = () => {
        var e = document.querySelector(".vertical-scrollable") as HTMLElement;
        var h = window.innerHeight;
        e.style.height = (h - 284) + "px";
        e.style.maxHeight = (h - 280) + "px";
        setTableBodyHeight(h - 284);
    };

    function remove(siteIdFilter: string[], id: string) {
        siteIdFilter.forEach((item, index) => {
            if (item === id) siteIdFilter.splice(index, 1);
        });
    }

    useEffect(() => {
        window.addEventListener("resize", onResize);

        if (sites?.length == 0) {
            init();
            onResize();
        }

        return () => {
            window.removeEventListener("resize", onResize);
        };

    }, [formData, filterData]);

    return (
        <>
            <Row className="">                  
                <Col className="">
                    <Card className="rounded">                        
                        <Card.Body className="">
                            <Row className="d-none d-md-block">
                                <Col>&nbsp;</Col>
                            </Row>
                            <Row className="d-none d-md-block">
                                <Col>&nbsp;</Col>
                            </Row>
                            <Row className="">
                                <Col sm="12" className="">    
                                    <h4 className="mb-2 qvend-title">Sites</h4>                                    
                                </Col>
                            </Row>
                            <form className="" onKeyDown={handleKeyDown}>
                                <Row style={{ fontSize: '11px' }}>
                                    <Col md={3} xs={5} style={{ paddingRight: 0 }}>
                                        <div className="bd-example">
                                            <div>
                                                <SearchInput className="form-control search" type="search" placeholder="Search" onChange={handleSearch} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={3} xs={1}>
                                        <button type="button" className="btn btn-qvend" style={{ fontSize: '11px', padding: '0.5rem 0.3rem' }}>...</button>
                                    </Col>                                    
                                    <Col className="text-end">
                                        <button type="button" className="btn btn-qvend" style={{ fontSize: '11px' }}>Download</button>
                                    </Col>
                                </Row>
                                <Row>
                                <Col md={12} xs={10} style={{ paddingRight: 0 }}>
                                </Col>
                                </Row>
                            </form>
                            <Row>
                                <Col sm="12" xs="12" className="mt-2">
                                    <RefreshPull isRefreshing={isRefreshing} onSetPage={handlePaging} />
                                    <div className="table-responsive-lg" style={{ overflowX: 'hidden' }}>
                                        <div>
                                            <Row className="qvend-table-font" style={{ background: "#2b2e83", color: "#FFFFFF", overflowY: "scroll" }}>
                                                <Col xl={1} lg={1} md={1} sm={1} xs={3} className="qvend-table table-column-first">Filter</Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={4} className="qvend-table">SiteId</Col>
                                                <Col xl={4} lg={4} md={4} sm={9} xs={5} className="qvend-table">Omschrijving</Col>
                                                <Col xl={5} lg={5} md={5} sm={0} xs={0} className="qvend-table d-none d-md-block">Klant</Col>
                                            </Row>                                            
                                        </div>
                                        <div className="vertical-scrollable" style={{ overscrollBehaviorY: 'contain' }}>
                                            <Row className={("qvend-table-font")} style={{ background: "#cfd5ea", color: "black" }}>
                                                <Col xl={1} lg={1} md={1} sm={1} xs={3} className="qvend-table search-in-row-first">
                                                    <Form.Check checked={ siteFilterQuery.length > 0 } onChange={handleSelectAll} /></Col>
                                                <Col xl={2} lg={2} md={2} sm={2} xs={4} className="qvend-table search-in-row-padding"><SearchInput className="form-control siteId" type="search" onChange={handleSearchColumn1} /></Col>
                                                <Col xl={4} lg={4} md={4} sm={9} xs={5} className="qvend-table search-in-row-padding"><SearchInput className="form-control description" type="search" onChange={handleSearchColumn2} /></Col>
                                                <Col xl={5} lg={5} md={5} sm={0} xs={0} className="qvend-table search-in-row-padding d-none d-md-block"><SearchInput className="form-control customerName" type="search" onChange={handleSearchColumn3} /></Col>
                                            </Row>
                                            <Row className="d-none errorMsg">
                                                <Col>
                                                    {
                                                        errorMsg.map((item, index) => {
                                                            return <Alert key={index} variant="warning" className="alert" role="alert">
                                                                <span>{item}</span>
                                                            </Alert>;
                                                        })
                                                    }
                                                </Col>
                                            </Row>
                                            {sites.map((site: Site, index) => (
                                                
                                                <Row className={"qvend-table-font"} style={{ background: (index % 2 ? "#cfd5ea" : "#e9ebf5"), color: "black", cursor: "pointer" }} onClick={(e) => { setData(site); handleMouseClickOnRow(e) }}>                                                    
                                                        <SiteItem
                                                            key={site.id}
                                                        site={site}
                                                        isChecked={(siteFilter !== null && siteFilter.indexOf(site.siteId) !== -1) || (siteFilterQuery.length > 0)}
                                                        isDisabled={siteFilterQuery.length > 0}
                                                            handleChange={(e: ChangeEvent<HTMLInputElement>) => { e.target.checked ? siteFilter?.push(site.siteId) : remove(siteFilter, site.siteId); setSiteFilter(siteFilter); setSiteFilterQuery(""); }}
                                                            rowColor={index % 2 ? "#cfd5ea" : "#e9ebf5"}
                                                        />                                                    
                                                    </Row>
                                                
                                                
                                                ))}
                                        </div>                                        
                                    </div>
                                </Col>                              
                            </Row>
                            <ListPagination
                                articlesCount={siteCount}
                                currentPage={currentPage}
                                onSetPage={handlePaging}
                                onRefresh={handleRefreshButton } />
                        </Card.Body>
                    </Card>
                </Col>                                    
            </Row>
        </>
    )
}

export default Sites
