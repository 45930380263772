import {
    ChargePointOperator,
    OpenAPI,
    ChargePointOperatorService
} from '../openapi';
import { ApiResult } from '../openapi/core/ApiResult';

const { get1, put, post } = ChargePointOperatorService;

OpenAPI.BASE = process.env.REACT_APP_API_ENDPOINT as string;

export const getChargePointOperatorBySiteId = async (token: string, siteId: string): Promise<ChargePointOperator> => {
    return await get1(token, siteId);
};

export const updateCpo = async (
    token: string,
    id: string,
    cpo: ChargePointOperator
): Promise<ChargePointOperator> => {
    return await put(token, id, cpo);
};

export const addCpo = async (
    token: string,    
    cpo: ChargePointOperator
): Promise<ChargePointOperator> => {
    const c = { ...cpo };
    c.propertyNames = {};
    return await post(token, c);
};
