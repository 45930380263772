
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Row, Col, Table, Form, FormCheck } from 'react-bootstrap';
import Card from '../../../components/Card';

import { getProducts } from '../../../services/api/product';
import { addLinkedItem, deleteAllLinkedItems, deleteLinkedItem, getLinkedItems } from '../../../services/api/linkedItem';
import { ApiError, LinkedItem, Product, ProductVmcLink, VmcStatusDto } from '../../../services/openapi';

import { useKeycloak } from '@react-keycloak/web';

import SearchInput from '../../../components/SearchInput';
import ListPagination from '../../../components/ListPagination';
import RefreshPull from '../../../components/RefreshPull';
import { ItemLinker } from '../../../features/item-linker';
import { Link, useNavigate } from 'react-router-dom';
import { updateProduct } from '../../../services/api/productVmcLink';
import { useLocalStorage } from '../../../hooks/useLocalStorage';

const LinkLocationToVmc = () => {
    const [error, setError] = useState<ApiError | null>();
    const [products, setProducts] = useState<Product[]>([]);
    const [formData, setFormData] = useState<string>("");
    const [searchData, setSearchData] = useState<Record<string, string>>({});
    const [locationCount, setProductCount] = useState<string>("0");
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pagingString, setPagingString] = useState<string>("0-25");
    const [filterData, setFilterData] = useState<string>("");
    const [showLinked, setShowLinked] = useState<boolean | null>(null);
    const [modified, setModified] = useState<boolean>(false);
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
    const [productVmcLink, setProductVmcLink] = useState<any>(JSON.parse(localStorage.getItem('ProductVmcLink') ?? ""));    
    const [linkedIds, setLinkedIds] = useState<number[]>([]);
    const [linkedProductIds, setLinkedProductIds] = useLocalStorage<number[]>("linkedProductIds", []);
    const [productName, setProductName] = useState<string>("");
    const [tableBodyHeight, setTableBodyHeight] = useState<number>(380);

    const { keycloak } = useKeycloak();
    const token = keycloak.token || '';

    const navigate = useNavigate();

    const delay = async (ms: number) => {
        await new Promise(resolve => setTimeout(resolve, ms));
    }

    let timer: NodeJS.Timeout | null = null;

    const submitSearch = () => {
        formData === "" ? setFormData(" ") : setFormData("");
    }

    const navigateBack = () => {
        navigate('/dashboard/details/vmc/product-vmc-link');
    };

    const setProductVmcLinkData = (data: ProductVmcLink) => {
        localStorage.setItem('ProductVmcLink', JSON.stringify(data));
    }

    const handleFilter = (e: boolean): void => {        
        setProducts([]);                    
        setShowLinked(e);                
    }

    const handleSearchColumn1 = (e: string): void => {
        setProducts([]);
        searchData["name"] = e;
        setSearchData(searchData);
        handleChange();
    }

    const handleRefreshButton = (): void => {
        setProducts([]);
        filterData === "" ? setFilterData(" ") : setFilterData("");
        setShowLinked(null);               
    }

    const handleChange = () => {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(submitSearch, 2000);
    }

    const handlePaging = async (e: number) => {
        do {
            await delay(1000);
        } while (isRefreshing);

        setCurrentPage(e);

        const start = ((e + 1) * 25) - 25;
        const end = ((e + 1) * 25);
        const rangeString = `${start}-${end}`
        setPagingString(rangeString);

        document.querySelector('#refresh')?.classList.remove("d-none")
        setIsRefreshing(true);

        getLinkedItems(token, productVmcLink!.vmcId, "Vmc", "Product").then((linkedIds) => {
            if (linkedIds.length > 0) {
                setLinkedIds(linkedIds)
            }
                            
            getProducts(keycloak.token || '', rangeString, { search: formData, linkTo: "Vmc", parentId: productVmcLink!.vmcId, linkedIds: linkedIds.map((x) => x).toString() }, searchData).then((result) => {
                const allProducts = result.body as Product[];
                if (showLinked === true) {
                    setProducts(allProducts.filter(x => linkedIds.indexOf(x.id) !== -1));
                } else if (showLinked === false) {
                    setProducts(allProducts.filter(x => linkedIds.indexOf(x.id) === -1));
                } else {
                    setProducts(allProducts);
                }

                var totalCount = result.header.split('/')[1];
                setProductCount(totalCount);

                document.querySelector('#refresh')?.classList.add("d-none");

                setIsRefreshing(false);
            }).catch((error) => setError(error));
        });                
    }

    const init = () => {
        handlePaging(0);        
    };            

    const onResize = () => {
        var e = document.querySelector(".vertical-scrollable") as HTMLElement;
        var h = window.innerHeight;
        e.style.height = (h - 284) + "px";
        e.style.maxHeight = (h - 280) + "px";
        setTableBodyHeight(h - 284);
    };

    useEffect(() => {
        window.addEventListener("resize", onResize);

        if (products?.length == 0) {
            init();            
            onResize();
        }

        return () => {
            window.removeEventListener("resize", onResize);
        };

    }, [formData, filterData, showLinked, linkedIds]);

    function remove(linkedIds: number[], id: number) {
        linkedIds.forEach((item, index) => {
            if (item === id) linkedIds.splice(index, 1);
        });
    }

    return (
        <>
            <Row className="">                  
                <Col className="">
                    <Card className="rounded">                        
                        <Card.Body className="">
                            <Row className="d-none d-md-block">
                                <Col>&nbsp;</Col>
                            </Row>
                            <Row className="d-none d-md-block">
                                <Col>&nbsp;</Col>
                            </Row>
                            <Row className="">
                                <Col sm="12" className="">
                                    <h4 className="mb-2 qvend-title">Automaat {productVmcLink!.identifier} - Producten koppelen</h4>                                    
                                </Col>
                            </Row>                            
                            <Row>
                                <Col sm="12" xs="12" className="mt-2">
                                    <RefreshPull isRefreshing={isRefreshing} onSetPage={handlePaging} />
                                    <div className="table-responsive-lg" style={{ overflowX: 'hidden' }}>
                                        <div>
                                            <Row className="qvend-table-font" style={{ background: "#2b2e83", color: "#FFFFFF", overflowY: "scroll" }}>
                                                <Col xl={8} lg={8} md={8} sm={8} xs={8} className="qvend-table table-column-first">Product</Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={4} className="qvend-table">Gekoppeld</Col>                                                
                                            </Row>                                            
                                        </div>
                                        <div className="vertical-scrollable" style={{ overscrollBehaviorY: 'contain' }}>
                                            <Row className={("qvend-table-font")} style={{ background: "#cfd5ea", color: "black" }}>
                                                <Col xl={8} lg={8} md={8} sm={8} xs={8} className="qvend-table search-in-row-first"><SearchInput className="form-control" type="search" placeholder="Search" onChange={handleSearchColumn1} /></Col>
                                                <Col xl={4} lg={4} md={4} sm={4} xs={4} className="qvend-table" style={{ padding: "10px 16px" }}><SearchInput className="form-check-input" type="checkbox" onChange={handleFilter} isChecked={showLinked != null ? showLinked === true : undefined} /></Col>
                                                </Row>
                                            {products.map((product: Product, index) => (                                                    
                                                    <ItemLinker
                                                        key={product.id}
                                                        itemId={product.id ?? 0}
                                                        title={product.note ?? product.description} 
                                                        itemNameColumn1={product.name ?? ""}
                                                        isChecked={linkedIds !== null && linkedIds.indexOf(product.id) !== -1}
                                                        isDisabled={linkedIds !== null && linkedIds.length > 0 && product.id !== linkedIds[0]} 
                                                        error={error === null ? undefined : error}
                                                        rowColor={index % 2 ? "#cfd5ea" : "#e9ebf5"}
                                                        handleChange={(e: ChangeEvent<HTMLInputElement>) => { setModified(true); setShowLinked(null); setProductName(product.name ?? ""); let v: ProductVmcLink = productVmcLink; e.target.checked ? linkedIds?.push(product.id) : remove(linkedIds, product.id); setLinkedIds(linkedIds); e.target.checked ? addLinkedItem(token, { parentId: v.vmcId ?? 0, parentType: 'Vmc', linkedEntityId: product.id ?? 0, linkedEntityType: "Product" }) : deleteLinkedItem(token, v.vmcId ?? 0, 'Vmc', product.id ?? 0, 'Product') }}
                                                    />
                                                ))}                                            
                                        </div>                                        
                                    </div>
                                </Col>                              
                            </Row>
                            <Row>
                                <ListPagination
                                    articlesCount={locationCount}
                                    currentPage={currentPage}
                                    onSetPage={handlePaging}
                                    onRefresh={handleRefreshButton} />
                            </Row>
                            <Row style={{ paddingTop: "3px" }}>
                                <Col>
                                    <button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px', backgroundColor: "red" }} onClick={() => { deleteAllLinkedItems(token, productVmcLink.id ?? 0, 'Vmc', "Product").then(() => navigateBack()) }}>Annuleren</button>                                    
                                </Col>
                                <Col className="text-end">
                                    <button type="button" className="btn btn-fixed-width" style={{ fontSize: '11px' }} onClick={() => { let v: ProductVmcLink = productVmcLink; let oldId = v.productId; v.productId = linkedIds[0]; v.productName = productName; updateProduct(token, oldId, v).then(() => setProductVmcLinkData(v)).then(() => deleteAllLinkedItems(token, productVmcLink.id ?? 0, 'Vmc', "Product").then(() => navigateBack())) }}>Opslaan</button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>                                    
            </Row>
        </>
    )
}

export default LinkLocationToVmc
