import {
    Site,
    OpenAPI,
    SiteService
} from '../openapi';
import { ApiResult } from '../openapi/core/ApiResult';

const { get, get1, put, getTotalCount } = SiteService;

OpenAPI.BASE = process.env.REACT_APP_API_ENDPOINT as string;

export const getSites = async (token: string, range: string, filter?: Record<string, string>, search?: Record<string, string>) => {
    const sites: ApiResult = await get(token, range, filter, search);
    return sites;
};

export const getSiteCount = async (token: string, filter?: Record<string, string>, search?: Record<string, string>): Promise<string> => {
    const result: string = await getTotalCount(token, filter, search);
    const count = result.split('/')[1];
    return count;
};

export const getSiteById = async (token: string, id: string): Promise<Site> => {
    return await get1(token, id);
};

export const updateSite = async (
    token: string,
    id: number,
    site: Site
): Promise<Site> => {
    const s = { ...site };
    s.propertyNames = {};
    return await put(token, id, s);
};

