import React from 'react';
import { ApiError } from '../../services/openapi';
import { Col, Row } from 'react-bootstrap';
import { ProductVmcLink } from '../../services/openapi/models/ProductVmcLink';

type ProductProps = {
    product: ProductVmcLink;
    rowColor: string;
    className?: string;
};

const ProductVmcLinkItem = ({ product, rowColor, className }: ProductProps) => {
    const selectionDescriptionNl = JSON.parse(product.selectionDescription!)["NL"]

    return (
        <>
            <Row className={("qvend-table-font " + (className ?? ""))} style={{ background: rowColor, color: "black" }}>
                <Col md={6} xs={8} className="qvend-table table-column-first">{selectionDescriptionNl}</Col>
                <Col md={6} xs={4} className="qvend-table" title={product.productId.toString()}>{product.productName}</Col>                
            </Row>
        </>
    );
};

export default ProductVmcLinkItem;
