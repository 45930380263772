import React, {  ChangeEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { forEachChild } from 'typescript';

interface inputAndSelectorProps {
    options: string[],
    initialTextPerOption: Record<string, string>,
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const InputWithSelectorField = (props: inputAndSelectorProps) => {
    const ENTER_KEY = 13

    const [texts, setTexts] = useState<Record<string, string>>(props.initialTextPerOption);
    const [result, setResult] = useState<string>("");
    const [select, setSelect] = useState<string>("0");
    const [evnt, setEvnt] = useState<any>(null);

    const { options } = props;

    const handleBlur = (e: React.FocusEvent) => {
        if (evnt == null || evnt.target == null) {
            return;
        }

        triggerChange();
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === ENTER_KEY && evnt != null && evnt.target != null) {
            triggerChange();
        }
    }

    const triggerChange = () => {
        let e = evnt;

        if (e == null) {
            return;
        }

        options.map((key, i) => {
            e.target.value = JSON.stringify(texts);
        })

        props.onChange(e as ChangeEvent<HTMLInputElement>);
    }

    return (
        <div style={{ display: 'flex' }}>
            {options.map((key, i) => (
                select === i.toString() && (
                    <Form.Control
                        type="text"
                        size="sm"
                        style={{ width:'90%' }}
                        value={texts[key]}
                        onChange={(e) => {
                            setTexts(
                                values => ({ ...values, [key]: e.target.value })
                            );

                            setEvnt(e);               
                        }}
                        onKeyDown={handleKeyDown}
                        onBlur={handleBlur}
                    />
                )))}
            <select className="form-control" style={{ width: '10%' }} value={select} onChange={(e) => { setSelect(e.target.value); }}>
                {options.map((text, i) => (
                    <option label={text} value={i} key={i} />
                ))}
            </select>            
        </div>
    );
}


export default InputWithSelectorField;