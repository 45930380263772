/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagingHeader } from '../models/PagingHeader';
import type { Location} from '../models/Location';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { requestHdr as __requestHdr } from '../core/request';
import { requestApiResult as __requestApiResult } from '../core/request';
import { ApiResult } from '../core/ApiResult';
import { ChargePointOperator } from '../models/ChargePointOperator';


export class ChargePointOperatorService {

    /**
     * @param id 
     * @returns 
     * @throws ApiError
     */
    public static async get1(token: string,
        siteId: string,
    ): Promise<ChargePointOperator> {
        return await __request(OpenAPI, {
            method: 'GET',
            url: `/api/OcppCpo/${siteId}`,
            headers: { Authorization: `Bearer ${token}` }
        });
    }

    public static async put(token: string,
        id: string,
        requestBody?: ChargePointOperator,
    ): Promise<any> {
        const result = await __request(OpenAPI, {
            method: 'PUT',
            url: `/api/OcppCpo/${id}`,
            headers: { Authorization: `Bearer ${token}` },
            body: requestBody,
        });
        return result;
    }

    public static post(token: string,
        requestBody?: ChargePointOperator,
    ): Promise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/OcppCpo',
            body: requestBody,
            mediaType: 'application/json',
            headers: { Authorization: `Bearer ${token}` }
        });
    }
}