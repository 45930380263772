/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagingHeader } from '../models/PagingHeader';
import type { Profit } from '../models/Profit';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { requestHdr as __requestHdr } from '../core/request';
import { requestApiResult as __requestApiResult } from '../core/request';
import { ApiResult } from '../core/ApiResult';

export class ProfitService {    

    public static async getProfitView(token: string,
        pagingRange: string,
        filter?: Record<string, string>,
        search?: Record<string, string>
    ): Promise<ApiResult> {
        return await __requestApiResult(OpenAPI, {
            method: 'GET',
            url: '/api/Query',
            headers: {
                Range: `pages=${pagingRange}`,
                Authorization: `Bearer ${token}`
            },
            query: {
                'filter': filter,
                'search': search,
                'command': 'profitView'
            },
            responseHeader: 'Content-Range'
        });
    }
}